import { Header as BaseHeader } from "@olmokit/components/Header";
import HeaderSubnav from "components/Header/Subnav";
import "components/SalesPortal";
import "components/Hamburger";
import "./index.scss";

/**
 * Component: Header
 *
 */
export default function Header() {
  const header = BaseHeader();

  HeaderSubnav();

  return {
    /**
     * Set variant
     *
     * @param {"light" | "dark"} variant
     */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
      );
    },
  };
}
