import { $ } from "@olmokit/dom";
import { hasGtm } from "@olmokit/core/analytics";
import AuthFormLogin from "@olmokit/core/auth/form-login";
import FormsInputMaterial from "@olmokit/core/forms/input/material";
import "@olmokit/core/forms/checkbox";
import "./index.scss";

/**
 * Component: Form login
 */
export function FormLogin() {
  const $error = $(".FormLogin: .error");

  AuthFormLogin(null, {
    succeded: (formData, instance, response) => {
      $error.innerHTML = response?.data?.msg || "";

      const {
        id,
        password,
        token,
        token_active,
        end_session,
        ...userTrackData
      } = response.data.user;

      if (hasGtm()) {
        dataLayer.push({
          ...userTrackData,
          userId: id,
          event: "form sent",
          form: "Login",
          eventCallback: function () {
            location.href = response.data.redirect;
          },
          eventTimeout: 2000,
        });
      } else {
        location.href = response.data.redirect;
      }
    },
    failed: (formData, instance, response) => {
      $error.innerHTML = response?.data?.msg || "";
      // setTimeout(() => {
      //   $error.innerHTML = "";
      // }, 3000);
    },
  });
  FormsInputMaterial();
}
