/**
 * @file Route: login
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "layouts/main";
import Header from "components/Header/Pro";
import { FormLogin } from "components/Form/Login";

import "./index.scss";

console.log("Route login/index.js mounted.");

Header();
FormLogin();
